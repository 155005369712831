import React, { Component } from "react";
import PropTypes from "prop-types";
import Header from "../components/header";

import "../assets/styles/reboot.min.css";
import "../assets/styles/global-styles.css";

class DefaultLayout extends Component {
  render() {
    return (
      <div id="site-wrapper">
        <Header />
        <main id="site-content" role="main">
          {this.props.children}
        </main>
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
